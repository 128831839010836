import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const color = '#555'

const Wrapper = styled.span`
  display: inline-block;
  margin: 0.1em;
  box-sizing: border-box;
  width: ${props => props.width ? `${props.width}px` : '100px'};
  height: ${props => props.height ? `${props.height}px` : 'auto'};
  & > svg {
    width: 100%;
    height: 100%;
    .fill {
        fill: ${props => props.color};
    }
    .line {
        stroke: ${props => props.color};
    }
  }
`

const Icon = ({ icon, ...props }) => {
  const svg = require(`!raw-loader!./icons/${icon}.svg`)
  return <Wrapper {...props} dangerouslySetInnerHTML={{ __html: svg }} />
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  reverse: PropTypes.bool,
}

export default Icon
