import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { Link } from 'gatsby'
// import './mallory-font.css'

import { Box as Bx, Card, Image as I, Heading as H, Text as T } from 'theme-ui'

import { ff, fb } from '../theme'

// export const ff = `"Mallory Book", Helvetica, sans-serif`
// export const fb = `"Mallory Bold", Helvetica, sans-serif`

// Colors
const palx = require('palx')
const primaryBase = '#000fb7'
const colors = palx(primaryBase)
const secondary ="#fff"

export const primary = colors.base

export const theme = {
  colors,
  secondary,
  primary,
}

export const IconForceFill = styled(I)`
  &svg path {
    fill: red;
  }
  margin-right: 16px;
`

export const Wrapper = styled(Bx)`
  margin: auto;
  color: ${primary};
  padding-left: 1rem;
  padding-right: 1rem;
`

export const WrapperColored = styled.div`
  position: relative;
  border-bottom: solid 1px #eee;
  background-color: #000;
`

export const PageTitle = styled.h1`
  // font-size: 34px;
  font-family: ${ff};
  width: 10em;
  font-weight: 600;
  color: ${(props) => (props.primary ? `${secondary}` : `${primary}`)};
`

export const PageDesc = styled.h2`
  // font-size: 22px;
  width: 16em;
  font-weight: 300;
  line-height: 1.25;
  font-family: ${ff};
  color: ${(props) => (props.primary ? `${secondary}` : `${primary}`)};
`
PageDesc.defaultProps = {
  theme: {
    color: '#000',
  },
}

export const Button = styled.button`
  // background-color: ${primary};
  color: ${colors.indigo[0]};
  // font-size: 16px;
  font-family: ${ff};
  padding: 8px 24px;
  border: 0;
  font-weight: bold;
  border-radius: 0;
`

export const Blocks = styled.div`
  display: flex;
`

export const Block = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`

export const Spacer = styled.div`
  padding-top: 48px;
`

export const BlockTitle = styled.h4`
  margin: 0;
  margin-bottom: 0px;
  font-family: ${ff};
  margin-bottom: 16px;
  color: ${colors.gray[9]};
  font-weight: 500;
`

export const BlockBody = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: ${ff};
  color: ${colors.gray[7]};
`

export const List = styled.ul`
  list-style: none;
  margin-left: 0;
  font-family: ${ff};
`

export const ListItem = styled.li`
  list-style: none;
  padding-right: 64px;
  font-family: ${ff};
  color: ${colors.gray[7]};
  margin-bottom: 8px;
`

export const Avatar = styled.img`
  width: 100%;
  height: auto;
`

export const AvatarBox = styled(Bx)`
  overflow: hidden;
  margin-bottom: 8px;
  margin-right: 16px;
  text-align: center;
  border-radius: 0;
`

export const SubTitle = styled.h5`
  // font-size: 16px;
  color: ${primary};
`

export const SubTitleSmall = styled.h5`
  // font-size: 14px;
  margin: 0;
  padding-bottom: 2px;
`

export const SubTitleAlt = styled.h5`
  // font-size: 14px;
  margin: 0;
  margin-top: 8px;
  font-weight: 200;
`

export const ListItemAlt = styled(ListItem)`
  margin-bottom: 24px;
`

export const ListAlt = styled(List)`
  display: flex;
`

export const SecHead = styled(PageDesc)`
  color: ${primary};
  margin-top: 16px;
  margin-bottom: 8px;
`

export const ButtonBase = styled(Link)`
  color: #fff;
  color: ${primary};
  // background-color: ${secondary};
  padding: 8px 16px;
  // font-size: 16px;
  font-family: ${ff};
  text-decoration: none;
  border-radius: 0;
  margin-top: 8px;
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  ${ifProp(
    'outline',
    css`
      // font-size: 16px;
      color: ${colors.indigo[0]};
    `
  )}
  ${ifProp(
    'alt',
    css`
      // font-size: 16px;
      color: ${colors.gray[9]};
      background-color: #fff;
      border: 0;
    `
  )}
  ${ifProp(
    'solid',
    css`
      // font-size: 16px;
      color: ${colors.gray[0]};
      // background-color: ${colors.gray[9]};
      border: 0;
    `
  )}
  ${ifProp(
    'link',
    css`
      background: none;
      padding: 0;
      // font-size: 21px;
    `
  )}
`

export const LinkBase = styled(Link)`
  color: #fff;
  color: ${primary};
  font-family: ${ff};
  text-decoration: none;
`

export const Illustration = styled.img`
  margin-top: 10%;
`

export const Icon = styled.img`
  fill: #000;
`

export const Heading = styled(H)`
  color: ${colors.gray[9]};
  font-family: ${ff};    
  // line-height: 1.15;
  ${ifProp(
    'flat',
    css`
      color: #000;
      line-height: 57px;
    `
  )}
  ${ifProp(
    'white',
    css`
      color: #fff;
      line-height: 1.3;
    `
  )}
  ${ifProp(
    'alt',
    css`
      color: ${colors.indigo[0]};
      line-height: 1.125;
    `
  )}
  ${ifProp(
    'pop',
    css`
      color: ${colors.indigo[9]};
    `
  )}
  ${ifProp(
    'invert',
    css`
      color: ${colors.indigo[9]};
    `
  )}
  ${ifProp(
    'bold',
    css`
      color: #000;
      line-height: 57px;
      font-family: ${fb};
    `
  )}
`

export const Text = styled(T)`
  // margin-top: 3px;
  color: ${colors.gray[9]};
  font-family: ${ff};    
  ${ifProp(
    'center',
    css`
      text-align: center;
    `
  )}
  
  ${ifProp(
    'invert',
    css`
      color: #fff;
    `
  )}
  ${ifProp(
    'flat',
    css`
      color: #000;
    `
  )}

  ${ifProp(
    'color',
    css`
      color: ${(props) => (props.color ? `${props.color}` : `#000`)};
    `
  )}

  // color
  ${ifProp(
    'alt',
    css`
      color: ${colors.indigo[0]};
      line-height: 1.25;
      opacity: 0.8;
    `
  )}
`

export const Box = styled(Bx)``

export const BoxRel = styled(Box)`
  position: relative;
  padding-top: 64px;
  padding-bottom: 80px;  
`

export const TextSpan = styled.span`
  color: ${theme.primary};
`

export const Image = styled(I)`
  // max-height: 300px;
`

export const BrandLogo = styled(Icon)`
  width: 120px;
  margin-bottom: 24px;
  margin-left: 0px;
`

export const HeaderBox = styled(Box)`
  padding-top: 24px;
  padding-bottom: 24px;
`

export const MenuItem = styled(Link)`
  display: inline-block;
  vertical-align: top;
  color: red;
`
export const MenuItemStyled = styled(MenuItem)`
  color: #000;
  text-decoration: none;
  margin-right: 24px;
`

export const Menu = styled.div`
  text-align: right;
  display: flex;
`

export const ColRight = styled.div`
  margin-left: auto !important;
  margin-left: 32px;
  a {
    margin-left: 32px;
    font-family: ${ff};
    text-decoration: none;
    color: ${theme.colors.gray[8]};
  }
`

export const MenuItemBrand = styled(MenuItem)`
  margin-right: 16px;
`

export const Body = styled(T)`
  font-family: ${ff};
  color: ${theme.colors.gray[6]};
  text-align: left;
  ${ifProp(
    'head',
    css`
      color: ${theme.colors.gray[9]};
    `
  )}
`

export const Padder = styled.div`
  padding-right: 40px;
  padding-top: 8px;
  text-align: left;
`

export const FooterLogo = styled(Icon)`
  margin-top: 32px;
  margin-bottom: 24px;
  margin-left: 0px;
  max-height: 40px;
  overflow: hidden;
`

export const Name = styled(T)`
  color: #000;
  font-family: ${ff};
  line-height: 1.25;
  ${ifProp(
    'section',
    css`
      color: ${colors.gray[8]};
    `
  )}
`

export const Container = styled(Box)`
  max-width: 80rem;
  // overflow: auto;
  // @media (max-width: 1280px) {
  //   max-width: 1140px;
  // }
  // @media (max-width: 1199.98px) {
  //   max-width: 960px;
  // }
  // @media (max-width: 991.98px) {
  //   max-width: 720px;
  // }
  // @media (max-width: 767.98px) {
  //   max-width: 540px;
  // }
  // @media (max-width: 575.98px) {
  // }
  ${ifProp(
    'bb',
    css`
      padding-bottom: 2rem;
      border-bottom: solid 1px #ddd;
    `
  )}

  ${ifProp(
    'bt',
    css`
      padding-top: 2rem;
      border-top: solid 1px #ddd;
    `
  )}
`
Container.defaultProps = {
  mx: 'auto',
}

export const Addr = styled.address`
  font-family: ${ff};
  font-style: normal;
  color: ${colors.gray[7]};
`

export const BlockHead = styled(H)`
  color: #000;
  line-height: 57px;
  font-family: ${fb};
  padding-bottom: 32px;
  border-bottom: 1px solid #000;
`
export const ExpertiseItem = styled(Box)`
  // margin-bottom: 98px;
  border-bottom: 1px solid #000;
`
