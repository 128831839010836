import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import {
  Wrapper,
  theme,
  MenuItemBrand,
  MenuItem,
  HeaderBox,
  Menu,
  Padder,
  Body,
  Container,
  IconForceFill,
  MenuItemStyled,
} from './atoms'

import { TextBase as Text, ContainerFull } from './blocks'

import Icon from './atoms/Icon'
// import { Text } from 'theme-ui'

import { Image, Box, Flex } from 'rebass'

const Tag = styled.span`
  color: #ddd;
  height: 200%;
  display: inline-block;
  margin-right: 16px;
  margin-left: 16px;
`

const FilledIcon = styled(Box)`
  text-align: right;
  svg path {
    fill: #000;
    opacity: 0.7;
  }
`

const ImageWhite = styled(Image)`
  path {
    fill: #000;
  }
  margin-right: 16px;
`

const Filler = styled(Box)`
  text-align: center;
`

const Linker = styled.a`
  text-align: right;
  margin-left: 16px;
`

const FooterWrapper = styled(Box)`
  text-align: right;
`

const Imagex = require('../images/logo-alt.svg')
const IconDribble = require('../images/dribbble.svg')
// const IconLinkedin = require('../images/linkedin.svg')
const IconFb = require('../images/facebook.svg')
const IconTwitter = require('../images/twitter.svg')
const IconInsta = require('../images/instagram.svg')

const Footer = ({ siteTitle }) => (
  <ContainerFull
    variant="styles.bigCard"
    py={0}
    sx={{ m: 0, bg: '#eee', pt: 3, pb: 6 }}
  >
    <Filler>
      <Container>
        <Flex
          mt={4}
          mb={4}
          alignItems="center"
          flexDirection={['column', 'row', 'row']}
        >
          <Box
            width={[1, 1, 2 / 12]}
            sx={{ opacity: '0.6' }}
            pl={[4, 4, 0]}
            pb={[4, 4, 0]}
          >
            <MenuItemBrand to="/">
              <FilledIcon>
                <Icon icon="logo" color="#fff" width={200} />
              </FilledIcon>
            </MenuItemBrand>
          </Box>
          <Box width={[1, 1, 5 / 12]} ml="auto" pl={[4, 4, 0]} pb={[4, 4, 0]}>
            <Flex mr="auto">
              <MenuItemStyled color="primary" to="/case-studies">
                Work
              </MenuItemStyled>
              <MenuItemStyled to="/services">Services</MenuItemStyled>
              <MenuItemStyled to="/blog">Blog</MenuItemStyled>
              <MenuItemStyled to="/team">Team</MenuItemStyled>
              <MenuItemStyled to="/careers">Careers</MenuItemStyled>
            </Flex>
          </Box>

          <Box width={[1, 1, 4 / 12]} pl={[4, 4, 0]} pr={0} ml="auto" mt={3}>
            <FilledIcon mb={2} ml="auto" sx={{ bg: 'red', textAlign: 'right', width: '100%' }}>
              <Linker target="_blank" href="https://twitter.com/muneef">
                <IconForceFill color="primary" width="24px" src={IconTwitter} />
              </Linker>
              <Linker target="_blank" href="https://fb.com/functionaryco">
                <IconForceFill color="primary" width="24px" src={IconFb} />
              </Linker>
              <Linker target="_blank" href="https://dribbble.com/functionaryco">
                <IconForceFill color="primary" width="24px" src={IconDribble} />
              </Linker>
              {/* <Linker
                target="_blank"
                href="https://instagram.com/functionarylabs"
              >
                <IconForceFill color="primary" width="24px" src={IconInsta} />
              </Linker> */}
            </FilledIcon>
          </Box>
        </Flex>
        <Flex py={4}>
          <Text variant="text.small">
            © Copyright 2020 Functionary Labs Pvt Ltd. All rights reserved.
          </Text>
          <Box ml="auto">
            <Text variant="text.small">Impressum -- Privacy Policy</Text>
          </Box>
        </Flex>
      </Container>
    </Filler>
  </ContainerFull>
)

export default Footer
