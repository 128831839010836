import React from 'react'

import styled, { css } from 'styled-components'
import { Text, Heading, Image, Container, Card, Box } from 'theme-ui'

import { LinkBase } from '../components/atoms'

// Name
export const Name = ({ children }) => (
  <Text mt={1} sx={{ fontSize: 1 }}>
    {children}
  </Text>
)

// SectionTitle

export const SectionTitle = ({ children }) => (
  <Text mt={1} sx={{ variant: 'text.big2' }} mt={2} mb={4} pl={0}>
    {children}
  </Text>
)

export const TextBase = ({ children, variant, ...props }) => (
  <Text sx={{ variant }} {...props}>
    {children}
  </Text>
)

export const HeadingBase = ({ children, variant, props }) => (
  <Heading sx={{ variant }} {...props}>
    {children}
  </Heading>
)

export const SectionInfo = ({ children }) => (
  <Text pb={2} sx={{ variant: 'text.bigInfo' }}>
    {children}
  </Text>
)

export const BlockTitle = ({ children }) => (
  <Text
    sx={{ fontSize: 2, variant: 'text.name', color: '#000' }}
    fontWeight={600}
  >
    {children}
  </Text>
)

export const ContainerWrapper = styled(Container)`
  max-width: 100% !important;
  position: relative;
`

export const ContainerFull = (props) => {
  return (
    <ContainerWrapper bg={ props.bgp ? props.bgp : "#fff"} variant={props.variant ? props.variant : 'styles.basic'}>
      <Container {...props} sx={{ borderBottom: 'none' }}>
        {props.children}
      </Container>
    </ContainerWrapper>
  )
}

export const WorkCard = ({ post }) => {
  return (
    <Card  sx={{ flex: 1, minWidth: ['100%', '50%', '33%'], mb: 6, mt: 3}}>
      <Box>
        <Image
          width={1}
          src={post.node.mainImage.asset.fixed.src}
          height={post.node.mainImage.asset.fixed.height}
          width={post.node.mainImage.asset.fixed.width}
        />
      </Box>
      <Box pt={3} style={{ position: 'relative' }}>
        <Text mb={3}>Product Design</Text>
        <LinkBase to={`/projects/${post.node.id}`}>
          <TextBase variant="text.cardTitle">{post.node.title}</TextBase>
        </LinkBase>
      </Box>
    </Card>
  )
}
