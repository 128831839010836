// Colors
const palx = require('palx')

const baseColor = '#053290'
const colors = palx(baseColor)

const altFont = '"Mallory"'
export const ff = `"Mallory", Helvetica, system-ui, sans-serif`
export const fb = `"Mallory Bold", Helvetica, system-ui, sans-serif`
// export const fb = `${altFont}, "Mallory Bold", Helvetica, system-ui, sans-serif`
// export const ff = `${altFont}, "Mallory Bold", Helvetica, system-ui, sans-serif`


const colorMap = {
  dark: "grays.9",
  medium: "grays.6",
  medium2: "grays.8",
  light: "grays.1",
  dim: "trans.0",
}

export default {
  colors: {
    primary: colors.base,
    black: '#000',
    white: '#fff',
    gray: '#212121',
    blue: '#000',
    // grays: ['#000000', '#212121', '#3b3b3b', '#585858', '#777777', '#979797', '#b9b9b9', '#dbdbdb', '#ffffff'],
    // grays:['#000000', '#1e1e1e', '#353535', '#4e4e4e', '#696969', '#858585', '#a2a2a2', '#c0c0c0', '#dfdfdf', '#ffffff'],
    // grays: ['#053290', '#1d459a', '#3358a4', '#496aae', '#5f7cb8', '#758ec2', '#8ba0cc', '#a2b3d6', '#b9c5e0', '#d0d8ea', '#e7ecf4', '#ffffff'],
    // grays: ["#f9f9f9", "#ededee", "#e0e1e1", "#d2d3d4", "#c3c4c5", "#b2b3b5", "#9fa0a2", '#88898c', "#6b6c6f", "#3e3f43"],
    grays: [
      '#f8f9fa',
      '#f1f3f5',
      '#e9ecef',
      '#dee2e6',
      '#ced4da',
      '#adb5bd',
      '#868e96',
      '#495057',
      '#343a40',
      '#212529',
    ],
    trans: [
      '#ffffffa3',
      '#ffffff00'
    ],
    // grays: ['#053290', '#39459c', '#555aa9', '#706fb5', '#8886c2', '#a09dce', '#b8b4da', '#d0cde6', '#f3f3f9', '#ffffff'],
    // darks: ['#053290', '#0f2d7f', '#14286e', '#16235f', '#171e4f', '#161a3f', '#141631', '#111022', '#0b0916', '#000000'],
  },
  breakpoints: ['40em', '52em', '75rem'],
  // fontSizes: [14,14,14,14,14,14,14,14,14,14],
  // fontSizes: [13.6, 17, 21.25, 26.56, 33, 41, 51, 64],
  // fontSizes: [13.33, 16,19.20,23.04,27.65, 33.18, 39.81],
  fontSizes: [14, 16, 20, 25, 31.25, 39.06, 48.83, 61.04],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256],
  fonts: {
    sans: ff,
    mono: 'Menlo, monospace',
    bold: fb,
    normal: ff,
    heading: fb,
    body: ff,
  },
  fontWeights: {
    body: 400,
    heading: 900,
    bold: 700,
  },
  lineHeights: {
    body: 1.4,
    heading: 1.125,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  sizes: {
    container: '80rem',
  },
  styles: {
    basic: {
      fontSize: 2,
      mt: 3,
      pb: 6,
      pt: 6,
      lineHeight: 'body',
    },
    section: {
      borderBottom: 'solid 1px',
      borderBottomColor: colorMap.light,
    },
    sectionBlock: {
      borderBottom: 'solid 1px',
      borderBottomColor: colorMap.medium,
    },
    bigCard: {
      mt: 0,      
      borderBottom: 'solid 1px',
      borderBottomColor: colorMap.light,
      lineHeight: 'body',
    },
    bigCardDim: {
      mt: 3,
      bg: colorMap.light,
      borderBottom: 'solid 1px',
      borderBottomColor: colorMap.light,
      lineHeight: 'body',
    },
    smallCard: {
      mt: 3,
      bg: 'white',
      lineHeight: 'body',
      textAlign: 'left',
    },
  },
  forms: {
    label: {
      mb: 2,
    },
    input: {
      mb: 2,
      borderRadius: 0,
      border: 'solid 1px #999',
    },
    textarea: {
      mb: 2,
      borderRadius: 0,
      border: 'solid 1px #999',
    },
  },
  text: {
    personName: {
      mt: 1,
      color: colorMap.medium,
    },
    boldLink: {
      fontWeight: 'heading',
    },
    blockInfo2: {
      fontSize: 2,
      mt: 3,
      lineHeight: 'body',
    },
    pageTitle: {
      fontSize: 4,
      fontWeight: 'bold',
    },
    pageDesc: {
      fontSize: 3,
      fontWeight: 'body',
      lineHeight: 'body',
      mt: 3,
      color: colorMap.medium,
    },
    small: {
      fontSize: 0,
      color: colorMap.medium,
      textAlign: 'left',
    },
    blockInfoInv: {
      fontWeight: 'body',
      fontSize: 4,
      mt: 3,
      color: colorMap.medium,
      lineHeight: 'heading',
    },
    cardTitle: {
      fontWeight: 'heading',
      fontFamily: fb,
      mt: 3,
      color: colorMap.dark,
      // color: 'black',
      lineHeight: 'heading',
      fontSize: 3,
      mb: 2,
      textAlign: 'left',
    },
    blockTitle: {
      fontWeight: 'heading',
      fontSize: 3,
      mt: 3,
      mb: 1,
      color: colorMap.dark,
      lineHeight: 'heading',
    },
    blockTitleCentre: {
      fontWeight: 'heading',
      fontSize: 3,
      mt: 3,
      mb: 2,
      color: colorMap.dark,
      lineHeight: 'heading',
      textAlign: 'center',
    },
    blockName: {
      fontWeight: 'bold',
      fontSize: 2,
      mb: 0,
      color: colorMap.dark,
      lineHeight: 'heading',
    },
    blockInfo: {
      fontSize: 1,
      fontWeight: 'body',
      lineHeight: 'body',
      textAlign: 'left',
      color: colorMap.dark,
      // ml: 3,
    },
    shortTitle: {
      fontSize: 0,
      my: 2,
      textTransform: 'uppercase',
      color: colorMap.medium,
    },
    sectionTitle: {
      fontWeight: 'heading',
      fontSize: 4,
      mt: 2,
      mb:3,
      color: colorMap.dark,
      lineHeight: 'heading',
    },
    sectionBody: {
      fontSize: 2,
      my: 2,
      color: colorMap.medium2,
      lineHeight: 'body',
    },
    blockInfoCenter: {
      fontSize: 2,
      pt: 2,
      textAlign: 'center',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    blockInfoDim: {
      fontSize: 2,
      pt: 2,
      fontWeight: 'bold',
      lineHeight: 'body',
      color: colorMap.medium2,
    },
    blockInfoStrong: {
      fontSize: 2,
      pt: 2,
      fontWeight: 'bold',
      lineHeight: 'body',
    },
    bigBlock: {
      fontWeight: 'body',
      fontSize: 3,
      mt: 3,
    },
    name: {
      fontWeight: 'bold',
    },
    big: {
      fontWeight: 'bold',
      color: colorMap.dark,
      fontFamily: 'heading',
      fontSize: 6,
      mb: 3,
      lineHeight: 'heading',
    },
    big2: {
      fontWeight: 'body',
      fontFamily: 'sans',
      fontSize: 3,
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },

    secTitle: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
      mb: 5,
    },
    centered: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
      textAlign: 'center',
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
}
