import React from 'react'
import { Link } from 'gatsby'
import { Wrapper, primary } from './atoms'
import Icon from './atoms/Icon'
import { Flex, Box, Container } from 'theme-ui'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import theme, { ff, fb } from '../theme'

const BrandLogo = styled(Icon)`
  margin-top: 32px;
  margin-bottom: 24px;
  margin-left: 0px;
  max-height: 40px;
  overflow: hidden;
`

const HeaderBox = styled.header`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: -1px -1px 5px #0e0f1054;
`

const MenuItem = styled(Link)`
  display: inline-block;
  vertical-align: top;
  color: #000;
  font-weight: ${theme.fontWeights.heading};
  font-family: ${fb};
  ${ifProp("alt", css`
    color: ${primary};
    font-family: ${fb};
    text-decoration: none;
  `)}
`

const Menu = styled(Flex)`  
  text-align: right;
  display: flex;
`

const ColRight = styled(Flex)`  
  margin-left: 32px;
  align-items: center;
  margin-left: auto;
  margin-right: 32px;
  ${MenuItem} {
    padding-left: 16px;
  }
  a {
    margin-left: 32px;
    text-decoration: none;
  }
`
const TalkBlock = styled(Flex)`
  align-items: center;
`

const MenuItemBrand= styled(MenuItem)`
  
`

// const Image = require('../images/logo.svg')

const Header = ({ siteTitle }) => (
  <HeaderBox>
    <Container sx={{ pt: 4, pb: 4}}>
      <Menu>
        <MenuItemBrand to="/">
          <BrandLogo icon="logo" color="#000" width={187}/>
        </MenuItemBrand>
        <ColRight>
          {/* <MenuItem to="/team">About</MenuItem> */}
          <MenuItem to="/case-studies">Works</MenuItem>
          <MenuItem to="/services">Services</MenuItem>
          {/* <MenuItem to="/blog">Blog</MenuItem> */}
          <MenuItem to="/team">Our Team</MenuItem>
          <MenuItem to="/talk">Hire us</MenuItem>
        </ColRight>
        {/* <TalkBlock align="center">
          <MenuItem alt to="/talk">Talk to us</MenuItem>
        </TalkBlock> */}
      </Menu>
    </Container>
  </HeaderBox>
)

export default Header
