import React from 'react'
import { ThemeProvider } from 'theme-ui'

import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import theme, { ff } from '../theme'
import Footer from './footer'

import Header from './header'

import './mallory-font.css'

import { Box } from 'theme-ui'

const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
    font-family: ${ff};
    background-color: #fff !important;    
    display: flex;
    flex-direction: column;
  }
  html, body {
    height: 100%;
  }
  .Site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  .Site-content {
    flex-grow: 1;
  }
`

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Header
        siteTitle={siteTitle}
        onHideNav={onHideNav}
        onShowNav={onShowNav}
        showNav={showNav}
      />
      <Box width={1} className="Site">
        <Box variant="styles.bigCard" width={1} className="Site-content">
          {children}
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
    
  </>
)

export default Layout
